import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import '../styles/global.scss';

import CollectionGallery from '../components/CollectionGallery';
import FixedNavbar from '../components/FixedNavbar';
import Layout from '../components/Layout';
import Section from '../components/layout/Section';

export default ({ data, location }) => {
  const collections = data.allContentfulCollection.edges
    .map(({ node }) => node)
    .sort((a, b) => b.year - a.year);

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="og:image" content="/og_image.jpg" />
        <meta
          name="og:title"
          content={`${data.site.siteMetadata.title} | Collections`}
        />
        <meta
          name="og:url"
          content={`${data.site.siteMetadata.url}/collections`}
        />
        <meta name="og:type" content="website" />
      </Helmet>
      <Layout location={location}>
        <FixedNavbar location={location} />
        <Section>
          {collections.map((c) => (
            <CollectionGallery
              key={c.id}
              collection={c}
              socialIcons={false}
              slice={4}
            />
          ))}
        </Section>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query CollectionsQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
    allContentfulCollection(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          title
          slug
          year
          gallery {
            ...ImageGridSizes
          }
        }
      }
    }
  }
`;
